nav {
  min-height: 10vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  button {
    background: transparent;
    cursor: pointer;
    border: 2px solid rgb(65, 65, 65);
    padding: 0.5rem;
    color: rgb(65, 65, 65);
    transition: all 0.3s ease;
    &:hover {
      background: rgb(65, 65, 65);
      color: white;
    }
    .music-icon {
      padding-left: 0.3rem;
    }
  }
}

@media (max-width: 768px) {
  button {
    z-index: 10;
  }
}
